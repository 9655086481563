@font-face {
  font-family: 'Almarai-Light';
  src: url(../fonts/Almarai-Light.ttf);
}

/* @font-face {
  font-family: 'Almarai-Medium';
  src: url(../fonts/Almarai-Medium.ttf);
} */
@font-face {
  font-family: 'Almarai';
  src: url(../fonts/Almarai-Regular.ttf);
}

@font-face {
  font-family: 'Almarai-SemiBold';
  src: url(../fonts/Almarai-SemiBold.ttf);
}

@font-face {
  font-family: 'Almarai-Bold';
  src: url(../fonts/Almarai-Bold.ttf);
}

.react-input-emoji--container {
  padding: 6px 0 7px !important;
  border-radius: 4px !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  font-size: 1rem !important;
  color: #263238 !important;
}
.react-emoji-picker--wrapper {
  width: 539px !important;
  right: 3px !important;
  top: 64px !important;
}
.emoji-mart {
  width: 495px !important;
}

.notifymessage > div > div.react-input-emoji--container {
  height: 120px !important;
}

.notifymessage
  > div
  > div.react-emoji-picker--container
  > div.react-emoji-picker--wrapper {
  top: 128px !important;
}

.errormessage > div > div.react-input-emoji--container {
  border-color: #f44336 !important;
}

.MuiFormControlLabel-root {
  width: 100% !important;
}
td.MuiTableCell-root.MuiTableCell-body.greenColor {
  color: #10981a;
}
td.MuiTableCell-root.MuiTableCell-body.redColor {
  color: #d42a0f;
}
.greenColor {
  color: #10981a;
}
.redColor {
  color: #d42a0f;
}
.percheck {
  width: 57% !important;
}

@media (min-width: 960px) {
  .MuiGrid-grid-md-3 {
    flex-grow: 0;
    max-width: 23% !important;
  }
}

.sun-editor .se-container {
  z-index: 0 !important;
}

.sun-editor-editable {
  background-color: #353434 !important;
}
.arabicdiv > div > div > div > div.sun-editor-editable {
  text-align: right;
}
.sun-editor .se-list-layer {
  max-height: 173px;
}
