@media all {
  .page-break {
    display: none;
  }
}

@media print {
  .page-break {
    display: block;
    page-break-before: auto;
  }
  @page {
    size: A4;
    margin: 3.5mm 0mm;
    display: flex;
    vertical-align: center;
    align-items: center;
    width: 21cm;
    height: 29.7cm;
    border: 5px solid #000;
  }
}

@page {
  size: A4;
  /* margin: 3mm 0mm; */
}

@media print {
  body,
  html {
    width: 210mm !important;
    height: 290mm !important;
    margin: 0;
  }
  .print-preview {
    width: 21cm;
    height: 29.7cm;
    margin: 0;
    border: 5px solid #000;
  }
}
